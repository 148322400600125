<template>
    <div>
    <a-page-header
        class="header_title"
        :title="title"
        @back="() => $router.push({path:'/media/medialist',query:{media_type_id:media_type_id}})"
    />
    <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" @submit="handleSubmit">
    <a-form-item :label="$t('medialist.add.item_1')">
      <a-select
        v-decorator="[
          'media_type_id',
          { rules: [{ required: true, message: $t('form.select_info') }] },
        ]"
        placeholder="Please select mediatype"
      >
        <a-select-option v-for="item in mediaTypes" :key="item.media_type_id" :value="item.media_type_id">
          {{item.name}}
        </a-select-option>
        
      </a-select>
    </a-form-item>
     <a-form-item :label="$t('medialist.add.item_2')">
      <a-input
        v-decorator="['asset', { rules: [{ required: true, message: $t('form.select_info') }] }]"
      />
    </a-form-item>
     <a-form-item :label="$t('medialist.add.item_3')">
      <a-input
        v-decorator="['url', { rules: [{ required: true, message: $t('form.select_info') }] }]"
      />
        </a-form-item>
      <a-form-item :label="$t('medialist.add.item_4')">
      <a-input
        v-decorator="['description', { rules: [{ required: true, message: $t('form.select_info') }] }]"
      />
    </a-form-item>
    <a-form-item :label="$t('medialist.add.item_5')">
      
        <Uploadimg :url="imageUrl"  @getImgUrl='getImgUrl($event)' />
    </a-form-item>
    <a-form-item :wrapper-col="{ span: 12, offset: 5 }" >
      <a-button type="primary" html-type="submit">
        {{$t('form.btn_submit')}}
      </a-button>
    </a-form-item>
  </a-form>


            
    </div>
</template>

<script>
import {addMedia,editMedia,getMediaTypes,getMediaDetail} from '@/network/mediarequest'
import Uploadimg from '@/components/content/UploadImgMedia'

export default {
    name: 'NodeprojectEditmedia',
    components: {Uploadimg},
    directives: {  },
    data() {
        return {
              media_type_id:'',
              title:this.$t('form.add'),
              loading: false,
              imageUrl: '',
              mediaTypes:[],
              formLayout: 'horizontal',
             form: this.$form.createForm(this, { name: 'coordinated' }),
        };
    },
    created() {
        this.initData()
        if(this.$route.query.media_type_id){
          this.media_type_id = this.$route.query.media_type_id;
        }
        if(this.$route.query.media_id){
          this.title = this.$t('form.edit');
          // media_id
          getMediaDetail(this.$route.query.media_id)
          .then(res=>{
            // console.log(res)
            let {asset,description,img,media_type_id,url}=res.body.media
            if(res.header.code==200){
              this.form.setFieldsValue({
                asset,description,media_type_id,url
              })
              this.imageUrl=img

            }
          })
        }

    },
    methods: {
        initData(){
          getMediaTypes()
                  .then(res=>{
                    if(res.header.code==200){
                      this.mediaTypes=res.body.media_types
                    }
          })

        },
      //上传后获取的url地址
        getImgUrl(e){
          this.imageUrl=e
        },
        //submit
        handleSubmit(e) {
        e.preventDefault();
        this.form.validateFields((err, values) => {
          if (!err) {
            // console.log(values);
            let obj=values
            obj.img=this.imageUrl
            if(this.$route.query.media_id){
              obj.media_id=this.$route.query.media_id
              editMedia(obj)
              .then(res=>{
                 if(res.header.code==200){
                        this.$message.success("success!")
                        this.$router.back(-1)
                        return ;
                  }
                    this.$message.success(res.header.msg)
              })
              return ;
            }

            addMedia(obj)
            .then(res=>{
            console.log(res)
              if(res.header.code==200){
                      this.$message.success("success!")
                      this.$router.back(-1)
                      return ;
                    }
              this.$message.success(res.header.msg)
            })
          }
        });
      },

    }
};
</script>

<style scoped>
.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>